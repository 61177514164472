import React from "react";

function SvgHeart(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={window.innerWidth < 800 ? "20em" : "30em"}
      height={window.innerWidth < 800 ? "8em" : "10.6em"}
      viewBox="0 0 924 458.127"
      {...props}
    >
      <ellipse
        cx={448.178}
        cy={584.715}
        rx={21.534}
        ry={6.76}
        transform="rotate(-69.082 218.693 574.488)"
        fill="#2f2e41"
      />
      <circle
        cx={408.371}
        cy={617.237}
        r={43.067}
        transform="rotate(-80.783 209.531 587.868)"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M250.746 430.1h13.084v23.442h-13.084zM276.913 430.1h13.084v23.442h-13.084z"
      />
      <ellipse
        cx={261.649}
        cy={453.814}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <ellipse
        cx={287.816}
        cy={453.269}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <circle
        cx={409.462}
        cy={606.333}
        r={14.719}
        transform="rotate(-1.683 -7179.488 5192.936)"
        fill="#fff"
      />
      <circle cx={271.462} cy={385.397} r={4.906} fill="#3f3d56" />
      <path
        d="M228.595 356.249c-3.478-15.574 7.638-31.31 24.828-35.149s33.944 5.675 37.422 21.249-7.915 21.318-25.105 25.156-33.668 4.318-37.145-11.256z"
        fill="#e6e6e6"
      />
      <ellipse
        cx={359.863}
        cy={597.253}
        rx={6.76}
        ry={21.534}
        transform="rotate(-64.626 116.207 595.878)"
        fill="#2f2e41"
      />
      <path
        d="M249.217 411.837c0 4.215 10.853 12.539 22.896 12.539s23.335-11.867 23.335-16.082-11.291.818-23.335.818-22.896-1.49-22.896 2.725z"
        fill="#fff"
      />
      <path
        d="M573.26 279.264l-46.78 46.17-105.72 104.32-2.14 2.11-11.92 11.76-24.99-24.69-2.2-2.17-45.69-45.13h-.01l-14.64-14.47-8.65-8.55-25.76-25.44-3.48-3.44-41.06-40.56a117.658 117.658 0 01-20.52-27.63 79.8 79.8 0 01-1.43-2.75 117.507 117.507 0 01124.71-168.53h.01c.38.06.75.12 1.13.2a113.605 113.605 0 0111.91 2.77 117.093 117.093 0 0129.12 12.93q1.425.885 2.82 1.8a118.172 118.172 0 0118.47 15.09l.35-.35.35.35a118.542 118.542 0 0110.83-9.58c.83-.65 1.67-1.29 2.51-1.91a117.45 117.45 0 0190.51-21.06 111.921 111.921 0 0111.92 2.78q1.965.555 3.9 1.2c1.04.34 2.08.69 3.11 1.07a116.425 116.425 0 0124.39 12.1q2.505 1.635 4.94 3.42a117.547 117.547 0 0114.01 178.19z"
        fill="#f2f230"
      />
      <path
        d="M526.48 325.434l-105.72 104.32-2.14 2.11-11.92 11.76-24.99-24.69-2.2-2.17-45.69-45.13c7.34-1.71 18.62.64 22.75 2.68 9.79 4.83 17.84 12.76 27.78 17.28a46.138 46.138 0 0028.33 3.13c17.82-3.74 31.61-17.52 43.77-31.08 12.16-13.57 24.59-28.13 41.68-34.42 9.01-3.32 18.69-4.07 28.35-3.79z"
        opacity={0.15}
      />
      <path
        d="M603.34 114.984a118.157 118.157 0 00-52.53-30.55c-1.32-.37-2.63-.7-3.96-1.01a116.83 116.83 0 00-17.39-2.79c-1.02-.1-2.04-.17-3.07-.22a115.155 115.155 0 00-15.43.06 118.397 118.397 0 00-74.84 33.45l-.36-.36-.36.36a118.614 118.614 0 00-46.7-28.08c-.99-.32-1.99-.63-2.99-.92a119.673 119.673 0 00-41.62-4.45c-.39.02-.78.05-1.16.09a118.306 118.306 0 00-69.4 29.4c-1.82 1.6-3.61 3.28-5.35 5.02a119.143 119.143 0 00-26.64 127.55c.35.94.73 1.87 1.13 2.8a118.153 118.153 0 0025.51 37.95l38.92 38.42 3.06 3.03 84.22 83.13 2.17 2.15 22.12 21.84 17.09 16.87 167.58-165.45a119.129 119.129 0 000-168.29zm-2.11 166.16l-165.47 163.36-14.95-14.76-21.65-21.37-2.17-2.14-82.58-81.53-3.01-2.97-41.1-40.58a115.193 115.193 0 01-24.76-36.72c-.4-.93-.78-1.86-1.13-2.79a116.134 116.134 0 0125.89-124.54q2.79-2.79 5.71-5.34h.01a115.38 115.38 0 0166.48-28.16q4.905-.42 9.82-.42c1.23 0 2.45.02 3.68.06a116.1 116.1 0 0129.65 4.8c.99.29 1.98.6 2.96.93a114.156 114.156 0 0129.33 14.49 115.614 115.614 0 0116.41 13.64l1.06 1.06.35-.35.35.35 1.06-1.06a115.674 115.674 0 0185.71-33.86c1.27.04 2.54.1 3.81.19 1.02.06 2.04.13 3.05.23a115.123 115.123 0 0119.09 3.35c1.34.34 2.67.71 3.99 1.12a115.96 115.96 0 0148.41 193.01z"
        fill="#3f3d56"
      />
      <path
        d="M368.88 87.774c-6.42 5.07-13.31 9.75-17.48 16.68-3.07 5.12-4.4 11.07-5.39 16.95-1.92 11.44-2.74 23.16-6.5 34.13-3.75 10.97-11.07 21.45-21.92 25.54-6.73 2.53-14.15 2.39-21.32 1.9-17.69-1.2-35.5-4.37-51.42-12.16-8.9-4.36-17.53-10.24-27.42-10.89a25.395 25.395 0 00-6.02.33 117.494 117.494 0 01131.57-79.99h.01c.38.06.75.12 1.13.2a113.605 113.605 0 0111.91 2.77 117.482 117.482 0 0112.85 4.54z"
        opacity={0.15}
      />
      <path
        d="M86.764 404.834a28.748 28.748 0 0027.76-4.89c9.724-8.162 12.773-21.607 15.253-34.057l7.337-36.823-15.36 10.576c-11.047 7.606-22.342 15.456-29.99 26.473s-10.987 26.057-4.842 37.978z"
        fill="#e6e6e6"
      />
      <path
        d="M88.077 449.416c-1.555-11.324-3.153-22.794-2.063-34.24.969-10.165 4.07-20.093 10.384-28.234a46.968 46.968 0 0112.05-10.92c1.205-.76 2.314 1.15 1.114 1.907a44.651 44.651 0 00-17.662 21.31c-3.845 9.78-4.462 20.442-3.8 30.832.401 6.283 1.25 12.524 2.106 18.758a1.144 1.144 0 01-.77 1.358 1.11 1.11 0 01-1.359-.77z"
        fill="#f2f2f2"
      />
      <path
        d="M103.052 429.377a21.162 21.162 0 0018.439 9.517c9.334-.443 17.115-6.957 24.12-13.142l20.72-18.294-13.713-.656c-9.861-.472-19.977-.912-29.37 2.129s-18.055 10.36-19.773 20.082z"
        fill="#e6e6e6"
      />
      <path
        d="M83.683 455.926c7.483-13.24 16.163-27.956 31.672-32.66a35.342 35.342 0 0113.321-1.375c1.415.122 1.061 2.303-.35 2.18a32.833 32.833 0 00-21.26 5.625c-5.994 4.08-10.66 9.753-14.61 15.768-2.42 3.685-4.588 7.526-6.756 11.361-.692 1.226-2.717.341-2.017-.899z"
        fill="#f2f2f2"
      />
      <circle cx={300.091} cy={76.051} r={43.067} fill="#2f2e41" />
      <path
        fill="#2f2e41"
        d="M280.465 109.85h13.084v23.442h-13.084zM306.632 109.85h13.084v23.442h-13.084z"
      />
      <ellipse
        cx={291.368}
        cy={133.565}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <ellipse cx={317.536} cy={133.02} rx={10.903} ry={4.089} fill="#2f2e41" />
      <circle cx={301.181} cy={65.148} r={14.719} fill="#fff" />
      <ellipse
        cx={444.181}
        cy={289.084}
        rx={4.886}
        ry={4.921}
        transform="rotate(-44.987 108.402 345.25)"
        fill="#3f3d56"
      />
      <path
        d="M258.314 36c-3.478-15.574 7.638-31.311 24.828-35.15s33.945 5.676 37.422 21.25-7.915 21.317-25.105 25.156-33.668 4.317-37.145-11.257z"
        fill="#e6e6e6"
      />
      <ellipse
        cx={770.709}
        cy={573.814}
        rx={6.76}
        ry={21.534}
        transform="rotate(-39.512 394.132 655.463)"
        fill="#2f2e41"
      />
      <circle
        cx={808.201}
        cy={616.798}
        r={43.067}
        transform="rotate(-9.217 -631.173 1362.286)"
        fill="#2f2e41"
      />
      <path
        fill="#2f2e41"
        d="M676.743 429.661h13.084v23.442h-13.084zM650.576 429.661h13.084v23.442h-13.084z"
      />
      <ellipse
        cx={678.924}
        cy={453.375}
        rx={10.903}
        ry={4.089}
        fill="#2f2e41"
      />
      <ellipse cx={652.756} cy={452.83} rx={10.903} ry={4.089} fill="#2f2e41" />
      <path
        d="M685.28 372.883c-13.578-11.22-21.124-21.507-10.96-33.808s29.412-13.178 42.99-1.958 16.344 30.286 6.18 42.587-24.633 4.398-38.21-6.821z"
        fill="#f2f230"
      />
      <circle
        cx={793.311}
        cy={594.45}
        r={14.719}
        transform="rotate(-1.683 -6795.638 5181.052)"
        fill="#fff"
      />
      <circle cx={650.138} cy={369.868} r={4.906} fill="#3f3d56" />
      <path
        d="M633.063 385.636c-2.98 2.98-5.088 12.645-.896 16.837s16.515-.268 19.496-3.248-4.51-3.353-8.702-7.545-6.918-9.024-9.898-6.044z"
        fill="#fff"
      />
      <ellipse
        cx={841.394}
        cy={654.275}
        rx={6.76}
        ry={21.534}
        transform="rotate(-20.918 173.966 917.594)"
        fill="#2f2e41"
      />
      <path d="M923 458.128H1a1 1 0 010-2h922a1 1 0 010 2z" fill="#ccc" />
    </svg>
  );
}

export const heart = SvgHeart();
