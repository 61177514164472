import React from "react";

function SvgInterview(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={window.innerWidth < 800 ? "20em" : "30em"}
      height={window.innerWidth < 800 ? "8em" : "10.6em"}
      viewBox="0 0 869.76 627"
      {...props}
    >
      <path
        d="M125.545 539.096v1.624a10.156 10.156 0 0010.147 10.147h86.052a10.151 10.151 0 0010.148-10.147v-1.624a10.075 10.075 0 00-2.355-6.494 10.439 10.439 0 00-1.713-1.624h-44.633a2.03 2.03 0 01-2.03-2.03h-45.469a10.162 10.162 0 00-10.147 10.148z"
        fill="#3f3d56"
      />
      <path fill="#3f3d56" d="M183.593 625.15H173.04v-74.685h10.554z" />
      <path
        d="M143.002 624.78c0 1.14 15.993.441 35.72.441s35.72.7 35.72-.441-15.993-10.183-35.72-10.183-35.72 9.043-35.72 10.183z"
        fill="#3f3d56"
      />
      <path
        d="M142.105 537.383l36.012 5.748 41.84 6.681 14.508 2.314a5.326 5.326 0 006.089-4.417l.657-4.107.609-3.824.503-3.15a5.266 5.266 0 00-1.006-4.026 5.14 5.14 0 00-1.973-1.624H183.19a2.03 2.03 0 01-2.03-2.03v-1.599a2.03 2.03 0 012.03-2.03h22.065l-10.172-1.623-31.271-4.993-18.257-2.914a5.337 5.337 0 00-6.105 4.424l-1.77 11.073a5.348 5.348 0 004.424 6.097z"
        fill="#ed2f2f"
      />
      <path
        d="M219.958 535.84v61.869a5.325 5.325 0 005.317 5.317h11.227a5.325 5.325 0 005.318-5.317V535.84a5.248 5.248 0 00-1.112-3.24 5.132 5.132 0 00-2.062-1.623h-15.522a5.16 5.16 0 00-2.054 1.624 5.264 5.264 0 00-1.112 3.239zM186.121 431.126a5.357 5.357 0 00.423 1.599 5.242 5.242 0 001.518 1.989l.024.016 32.034 25.986.47.373 6.634 5.383 2.005 1.623h34.688l-1.997-1.623-27.415-22.244-18.37-14.905-14.297-11.592a5.265 5.265 0 00-2.938-1.17.026.026 0 00-.017-.007 5.397 5.397 0 00-.958.016 5.114 5.114 0 00-1.388.34 3.907 3.907 0 00-.86.44 5.127 5.127 0 00-1.324 1.16l-6.965 8.58-.105.139a5.278 5.278 0 00-1.162 3.897z"
        fill="#ed2f2f"
      />
      <path
        d="M188.086 434.73a13.162 13.162 0 002.59 4.083l21.326 22.91a5.317 5.317 0 007.525-.333l.593-.674.26-.292 9.214-10.335 3.962-4.448a4.673 4.673 0 00.681-.91c.098-.162.187-.332.268-.503a5.385 5.385 0 00.447-2.41 5.246 5.246 0 00-1.746-3.71l-24.727-18.9a13.244 13.244 0 00-9.58-2.646.026.026 0 00-.016-.008 13.922 13.922 0 00-2.24.47c-.325.09-.641.203-.966.325a13.085 13.085 0 00-5.399 3.816 13.255 13.255 0 00-2.192 13.565z"
        fill="#2f2e41"
      />
      <circle cx={177.96} cy={357.005} r={40.69} fill="#ed2f2f" />
      <path
        d="M167.125 531.774l15.092 2.403.34.056 22.942 3.662 14.459 2.313 21.253 3.394.609.097 3.158.503 14.507 2.314a5.326 5.326 0 006.088-4.416l1.267-7.923.503-3.15c0-.016.008-.033.008-.049h-84.16a2.03 2.03 0 01-2.03-2.03v-1.599a2.03 2.03 0 012.03-2.03h26.595v-8.872l-1.624-.26-8.02-1.283-29.575-4.716a5.328 5.328 0 00-6.096 4.416l-.658 4.1-1.112 6.981a5.332 5.332 0 004.424 6.089z"
        fill="#ed2f2f"
      />
      <path
        d="M122.581 495.827c0 33.17 3.37 53.01 55.325 47.328l.211-.024a154.268 154.268 0 0027.382-5.236 81.322 81.322 0 0013.51-5.293 60.99 60.99 0 002.97-1.624h-38.788a2.03 2.03 0 01-2.03-2.03v-1.599a2.03 2.03 0 012.03-2.03h26.595v-51.273a5.96 5.96 0 015.95-5.95h21.635c-.187-.536-.373-1.08-.576-1.624a132.997 132.997 0 00-7.201-16.383 124.963 124.963 0 00-13.46-20.766 86.483 86.483 0 00-12.047-12.331c-8.143-6.722-17.008-10.878-26.002-10.878-33.17 0-55.504 56.542-55.504 89.713zM177.105 372.086l-.042-1.52c2.829-.08 5.323-.257 7.197-1.626a4.674 4.674 0 001.81-3.443 2.672 2.672 0 00-.876-2.2c-1.244-1.05-3.246-.71-4.705-.042l-1.258.577 2.412-17.631 1.507.206-2.052 14.998a5.368 5.368 0 015.077.73 4.16 4.16 0 011.414 3.416 6.183 6.183 0 01-2.433 4.617c-2.407 1.76-5.536 1.847-8.05 1.918zM190.6 347.701h8.189v1.521H190.6zM164.751 347.701h8.189v1.521h-8.189z"
        fill="#2f2e41"
      />
      <path
        d="M145.486 345.825c3.165-4.763 8.733-6.783 14.224-7.274 6.18-.553 12.152.64 18.187 1.797 6.64 1.274 13.669 2.467 20.36.855a20.072 20.072 0 0013.645-11.022 25.675 25.675 0 001.495-17.12 31.12 31.12 0 00-10.179-15.45c-9.63-8.236-22.662-11.937-35.197-11.589a59.507 59.507 0 00-34.498 12.13 56.03 56.03 0 00-19.877 31.15 58.411 58.411 0 002.829 36.74c2.743 6.363 6.71 12.1 9.77 18.302 2.85 5.776 5.046 12.49 3.272 18.932a17.56 17.56 0 01-11.86 12.209 12.455 12.455 0 01-13.85-5.654c-2.866-4.521-2.935-10.443 1.37-14.093a10.567 10.567 0 0115.243 1.521 10.877 10.877 0 012.176 7.975c-.212 1.436 1.985 2.055 2.2.606a12.866 12.866 0 00-23.99-8.308c-2.596 4.881-1.195 10.769 2.1 14.94a14.562 14.562 0 0014.847 5.34c5.999-1.386 10.906-6.153 13.214-11.756 2.569-6.237 1.392-13.197-1.113-19.25-2.707-6.542-6.727-12.41-9.907-18.714a53.3 53.3 0 01-5.408-18.211 57.623 57.623 0 018.218-36.5 53.555 53.555 0 0129.498-22.418 55.97 55.97 0 0137.035.98c11.59 4.566 22.78 14.518 22.646 27.883-.057 5.71-2.177 11.532-6.55 15.34-5.226 4.551-12.503 4.94-19.073 4.23-6.611-.715-13.032-2.646-19.672-3.138-5.775-.429-12.006.017-17.163 2.886a17.16 17.16 0 00-5.961 5.53c-.815 1.226 1.16 2.37 1.97 1.151z"
        fill="#2f2e41"
      />
      <path
        d="M151.96 445.413a4.279 4.279 0 00.22.43 4.612 4.612 0 00.268.423l17.795 25.612 1.38 1.99 14.515 20.879 14.003 20.157 5.667 8.167a4.732 4.732 0 00.512.625 5.302 5.302 0 003.32 1.624h.146v-34.835l-1.624-2.338-18.209-26.205-19.556-28.146a5.328 5.328 0 00-7.404-1.34l-6 4.165-3.222 2.241a5.317 5.317 0 00-1.81 6.551z"
        fill="#ed2f2f"
      />
      <path
        d="M149.176 444.788l.804 1.616.008.008.22.43 13.045 25.897a5.336 5.336 0 007.315 1.786l.844-.528.21-.13 16.822-10.553a5.315 5.315 0 001.77-7.339l-18.478-25.052a13.28 13.28 0 00-16.22-4.19 13.06 13.06 0 00-1.802.991 13.284 13.284 0 00-4.538 17.064z"
        fill="#2f2e41"
      />
      <path
        d="M244.978 530.978v61.121a5.325 5.325 0 005.317 5.317h11.228a5.325 5.325 0 005.317-5.317v-61.121z"
        fill="#ed2f2f"
      />
      <path
        d="M179.538 527.35v1.599a3.666 3.666 0 002.939 3.588c.113.016.227.04.34.048.122.009.244.017.374.017H308.34a7.584 7.584 0 007.582-7.574v-50.982a7.584 7.584 0 00-7.582-7.574h-92.604a7.583 7.583 0 00-7.574 7.574v49.65h-24.971a3.653 3.653 0 00-3.653 3.653z"
        fill="#fff"
      />
      <path
        d="M179.538 527.35v1.599a3.666 3.666 0 002.939 3.588c.113.016.227.04.34.048.122.009.244.017.374.017H308.34a7.584 7.584 0 007.582-7.574v-50.982a7.584 7.584 0 00-7.582-7.574h-92.604a7.583 7.583 0 00-7.574 7.574v49.65h-24.971a3.653 3.653 0 00-3.653 3.653zm1.623 1.599v-1.6a2.03 2.03 0 012.03-2.03h26.595v-51.273a5.96 5.96 0 015.95-5.95h92.604a5.96 5.96 0 015.958 5.95v50.982a5.96 5.96 0 01-5.958 5.95H183.19a2.03 2.03 0 01-2.029-2.03z"
        fill="#3f3d56"
      />
      <circle cx={262.041} cy={499.538} r={6.243} fill="#ed2f2f" />
      <path fill="#3f3d56" d="M208.187 524.557h1.624v7.306h-1.624z" />
      <path
        d="M868.76 32.087h-432a1 1 0 010-2h432a1 1 0 010 2z"
        fill="#cacaca"
      />
      <circle cx={474.743} cy={9.043} r={9.043} fill="#3f3d56" />
      <circle cx={505.984} cy={9.043} r={9.043} fill="#3f3d56" />
      <circle cx={537.224} cy={9.043} r={9.043} fill="#3f3d56" />
      <path
        d="M519.094 186.269a53.135 53.135 0 1153.134-53.135 53.195 53.195 0 01-53.134 53.135zm0-104.269a51.135 51.135 0 1051.134 51.134A51.193 51.193 0 00519.094 82z"
        fill="#cacaca"
      />
      <circle cx={516.133} cy={427.968} r={24.709} fill="#e4e4e4" />
      <path
        d="M502.407 437.126l-.025-.923c1.717-.048 3.232-.156 4.37-.987a2.838 2.838 0 001.1-2.091 1.623 1.623 0 00-.533-1.337 2.797 2.797 0 00-2.857-.025l-.764.35 1.465-10.706.915.125-1.246 9.108a3.26 3.26 0 013.083.443 2.526 2.526 0 01.86 2.074 3.755 3.755 0 01-1.478 2.804c-1.463 1.069-3.363 1.122-4.89 1.165zM510.602 422.318h4.973v.923h-4.973zM494.905 422.318h4.973v.923h-4.973z"
        fill="#2f2e41"
      />
      <path
        d="M512.414 412.01c-5.961 4.092-15.117 1.836-17.82-5.076a12.739 12.739 0 01.526-10.465 13.351 13.351 0 019.302-6.41c5.112-1.06 10.397.362 15.07 2.429a.929.929 0 001.054-.136c4.464-3.559 10.948-2.014 15.525.474a34.44 34.44 0 0112.184 11.526 35.99 35.99 0 015.671 15.804 36.073 36.073 0 01-14.078 32.04c-.886.663-.02 2.174.878 1.502a37.26 37.26 0 00-2.834-61.834c-5.49-3.302-13.14-5.076-18.576-.742l1.054-.136c-4.674-2.067-9.794-3.526-14.945-2.848-4.375.576-8.673 2.673-11.182 6.41a14.311 14.311 0 00-1.713 11.694 12.993 12.993 0 007.815 8.57 14.089 14.089 0 0012.947-1.3c.917-.63.048-2.137-.878-1.502z"
        fill="#2f2e41"
      />
      <circle cx={517.861} cy={141.126} r={24.391} fill="#e4e4e4" />
      <path
        d="M512.104 151.365c-1.58-.044-3.548-.098-5.064-1.206a3.89 3.89 0 01-1.53-2.904 2.616 2.616 0 01.889-2.149 3.376 3.376 0 013.194-.46l-1.291-9.434.947-.13 1.518 11.091-.791-.363a2.896 2.896 0 00-2.96.026 1.681 1.681 0 00-.55 1.385 2.94 2.94 0 001.138 2.166c1.18.861 2.748.973 4.527 1.022zM498.464 136.026h5.152v.957h-5.152zM514.724 136.026h5.152v.957h-5.152z"
        fill="#2f2e41"
      />
      <path
        d="M520.124 123.097c-2.963 3.875-7.668 6.475-12.64 5.86a10.993 10.993 0 01-9.62-8.612 14.19 14.19 0 013.728-12.525c3.351-3.525 8.148-5.421 12.895-6.072a32.105 32.105 0 0125.03 7.845 30.66 30.66 0 017.983 10.7 29.997 29.997 0 012.475 12.95 32.718 32.718 0 01-10.753 23.387 30.308 30.308 0 01-2.586 2.026c-.758.532-1.475-.712-.724-1.239a30.743 30.743 0 0012.33-20.693 28.659 28.659 0 00-6.72-23.118 31.464 31.464 0 00-21.886-10.668c-8.393-.366-18.881 3.381-20.452 12.724-.703 4.187.862 8.589 4.699 10.685a11.481 11.481 0 0012.677-1.578 14.561 14.561 0 002.325-2.397c.554-.723 1.8-.01 1.24.725z"
        fill="#2f2e41"
      />
      <path
        d="M519.094 326.434a53.135 53.135 0 1153.134-53.135 53.195 53.195 0 01-53.134 53.135zm0-104.269a51.135 51.135 0 1051.134 51.134 51.193 51.193 0 00-51.134-51.134zM519.094 476a53.135 53.135 0 1153.134-53.134A53.194 53.194 0 01519.094 476zm0-104.269a51.135 51.135 0 1051.134 51.135 51.193 51.193 0 00-51.134-51.135z"
        fill="#cacaca"
      />
      <circle cx={515.477} cy={278.377} r={25.234} fill="#e4e4e4" />
      <path
        d="M507.08 288.97c-1.636-.045-3.672-.102-5.24-1.248a4.024 4.024 0 01-1.584-3.005 2.707 2.707 0 01.92-2.223 3.493 3.493 0 013.305-.475l-1.336-9.76.98-.135 1.57 11.474-.818-.375a2.996 2.996 0 00-3.062.027 1.74 1.74 0 00-.57 1.433 3.041 3.041 0 001.178 2.24c1.22.892 2.843 1.007 4.684 1.058zM492.967 273.1h5.33v.99h-5.33zM509.79 273.1h5.33v.99h-5.33z"
        fill="#2f2e41"
      />
      <path
        d="M516.747 304.398l17.454-6.774c2.854-1.108 5.724-2.182 8.563-3.328a9.76 9.76 0 003.296-1.942 6.477 6.477 0 001.643-3.18 32.757 32.757 0 00.453-7.968 60.711 60.711 0 00-.704-8.53 41.208 41.208 0 00-5.27-15.032c-4.894-8.03-13.327-14.073-23.028-13.59a19.72 19.72 0 00-12.344 4.905 7.794 7.794 0 01-1.165 1.129 1.617 1.617 0 01-.928.062q-.611-.04-1.223-.06a25.254 25.254 0 00-4.238.178 13.419 13.419 0 00-6.991 2.903 8.526 8.526 0 00-3.164 5.71 6.759 6.759 0 002.283 5.517c2.082 1.755 5.053 1.138 7.55 1.006l9.151-.485 4.636-.246c.955-.05.952 1.434 0 1.485l-12.322.652a53.866 53.866 0 01-6.003.244 6.716 6.716 0 01-4.123-1.659 8.227 8.227 0 01-2.492-7.72 11.035 11.035 0 015.545-7.042c3.605-2.034 7.91-2.229 11.944-1.928l-.525.217c4.563-4.897 11.553-6.965 18.114-6.241 6.928.764 13.09 4.629 17.518 9.914 4.936 5.892 7.626 13.24 8.671 20.795a69.19 69.19 0 01.536 12.31 15.511 15.511 0 01-.917 5.32 7.615 7.615 0 01-3.575 3.833 51.159 51.159 0 01-5.674 2.33l-6.43 2.496-12.63 4.903-3.216 1.248c-.891.346-1.277-1.089-.395-1.432z"
        fill="#2f2e41"
      />
      <path
        d="M536.521 246.287a7.67 7.67 0 117.67 7.67 7.678 7.678 0 01-7.67-7.67zm1.484 0a6.185 6.185 0 106.185-6.184 6.192 6.192 0 00-6.185 6.184z"
        fill="#2f2e41"
      />
      <path
        d="M830.336 155.437H655.709a8.225 8.225 0 010-16.45h174.627a8.225 8.225 0 110 16.45zM774.025 127.282H655.709a8.225 8.225 0 010-16.45h118.316a8.225 8.225 0 110 16.45zM830.336 295.602H655.709a8.225 8.225 0 010-16.45h174.627a8.225 8.225 0 110 16.45zM774.025 267.447H655.709a8.225 8.225 0 010-16.45h118.316a8.225 8.225 0 010 16.45zM830.336 445.168H655.709a8.225 8.225 0 010-16.45h174.627a8.225 8.225 0 110 16.45zM774.025 417.013H655.709a8.225 8.225 0 010-16.45h118.316a8.225 8.225 0 110 16.45z"
        fill="#e4e4e4"
      />
      <path d="M382 627H1a1 1 0 010-2h381a1 1 0 110 2z" fill="#3f3d56" />
    </svg>
  );
}

export const interview = SvgInterview();
